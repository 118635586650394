import React from "react"
import Seo from "../components/seo"
import { Container } from "@material-ui/core/"
const NotFound = () => (
  <Container component="main" maxWidth="lg" className="login-wrapper">
    <Seo title="Not Found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Container>
)

export default NotFound
